<template>
    <Loading :value="nounsAsyncData.data.value">
        <ModerationSection
            v-model="filter.moderation"
            kind="nouns"
            :moderation-filters="['unapproved', 'no category']"
            :entries="Object.values(nouns)"
        />

        <FilterBar
            v-model="filter.text"
            v-model:category="filter.category"
            :categories="config.nouns.categories"
            submit-button
            @submit-clicked="form?.focus()"
        />

        <Table ref="dictionarytable" :data="visibleNouns" :columns="3" :marked="(el: Noun) => !el.approved" fixed>
            <template #header>
                <th v-for="gender in genders" :key="gender" class="text-nowrap">
                    <NounGenderLabel :gender="gender" />
                </th>
                <th></th>
            </template>

            <template #row="s">
                <template v-if="s">
                    <td>
                        <Noun :noun="s.el" gender="masc" />

                        <small v-if="s.el.base && nouns[s.el.base]">
                            <p><strong><T>nouns.edited</T><T>quotation.colon</T></strong></p>
                            <Diff switchable>
                                <template #before><Noun :noun="nouns[s.el.base]" gender="masc" /></template>
                                <template #after><Noun :noun="s.el" gender="masc" /></template>
                            </Diff>
                        </small>
                    </td>
                    <td>
                        <Noun :noun="s.el" gender="fem" />

                        <small v-if="s.el.base && nouns[s.el.base]">
                            <p><strong><T>nouns.edited</T><T>quotation.colon</T></strong></p>
                            <Diff switchable>
                                <template #before><Noun :noun="nouns[s.el.base]" gender="fem" /></template>
                                <template #after><Noun :noun="s.el" gender="fem" /></template>
                            </Diff>
                        </small>
                    </td>
                    <td>
                        <Noun :noun="s.el" gender="neutr" />

                        <small v-if="s.el.base && nouns[s.el.base]">
                            <p><strong><T>nouns.edited</T><T>quotation.colon</T></strong></p>
                            <Diff switchable>
                                <template #before><Noun :noun="nouns[s.el.base]" gender="neutr" /></template>
                                <template #after><Noun :noun="s.el" gender="neutr" /></template>
                            </Diff>
                        </small>

                        <div v-if="s.el.sourcesData.length" class="div-three-columns">
                            <p><strong><T>sources.referenced</T><T>quotation.colon</T></strong></p>
                            <ul class="list-unstyled">
                                <li v-for="source in s.el.sourcesData">
                                    <SourceItem :source="source" />
                                </li>
                            </ul>
                        </div>
                    </td>
                    <td>
                        <ul class="list-unstyled list-btn-concise">
                            <template v-if="$isGranted('nouns')">
                                <li v-if="s.el.author" class="small">
                                    <nuxt-link :to="`/@${s.el.author}`" class="btn btn-concise btn-outline-dark btn-sm m-1">
                                        <Icon v="user" />
                                        <span class="btn-label">
                                            <T>crud.author</T><T>quotation.colon</T>
                                            @{{ s.el.author }}
                                        </span>
                                    </nuxt-link>
                                </li>
                                <li v-if="!s.el.approved">
                                    <button class="btn btn-concise btn-success btn-sm m-1" @click="approve(s.el)">
                                        <Icon v="check" />
                                        <span class="btn-label"><T>crud.approve</T></span>
                                    </button>
                                </li>
                                <li v-else @click="hide(s.el)">
                                    <button class="btn btn-concise btn-outline-secondary btn-sm m-1">
                                        <Icon v="times" />
                                        <span class="btn-label"><T>crud.hide</T></span>
                                    </button>
                                </li>
                                <li>
                                    <button class="btn btn-concise btn-outline-danger btn-sm m-1" @click="remove(s.el)">
                                        <Icon v="trash" />
                                        <span class="btn-label"><T>crud.remove</T></span>
                                    </button>
                                </li>
                            </template>
                            <li>
                                <button class="btn btn-concise btn-outline-primary btn-sm m-1" @click="edit(s.el)">
                                    <Icon v="pen" />
                                    <span class="btn-label">
                                        <T v-if="$isGranted('nouns')">crud.edit</T>
                                        <T v-else>nouns.edit</T>
                                    </span>
                                </button>
                            </li>
                            <li>
                                <a
                                    :href="`/api/nouns/${s.el.id}.png`"
                                    target="_blank"
                                    rel="noopener"
                                    class="btn btn-concise btn-outline-primary btn-sm m-1"
                                >
                                    <Icon v="image" />
                                    <span class="btn-label">
                                        <T>nouns.image</T>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </td>
                </template>
            </template>

            <template #empty>
                <Icon v="search" />
                <T>nouns.empty</T>
            </template>
        </Table>

        <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />

        <template v-if="config.nouns.submit">
            <Separator icon="plus" />

            <div class="px-3">
                <NounSubmitForm ref="form" @submit="reloadNouns()" />
            </div>
        </template>
    </Loading>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import useDialogue from '../composables/useDialogue.ts';
import { Noun, genders } from '../src/classes.ts';
import type { NounRaw } from '../src/classes.ts';
import { buildDict } from '../src/helpers.ts';

import type NounSubmitForm from './NounSubmitForm.vue';
import type Table from './Table.vue';

export default defineComponent({
    props: {
        load: { type: Boolean },
    },
    setup() {
        const config = useConfig();
        const filter = useFilterWithCategory();

        const dictionarytable = useTemplateRef<InstanceType<typeof Table>>('dictionarytable');
        watch(filter, () => {
            if (dictionarytable.value) {
                dictionarytable.value.reset();
                dictionarytable.value.focus();
            }
        });

        const nounsAsyncData = useAsyncData(async () => {
            const nounsRaw = await $fetch<NounRaw[]>('/api/nouns');

            return buildDict(function* () {
                const sorted = nounsRaw.sort((a, b) => {
                    if (a.approved && !b.approved) {
                        return 1;
                    }
                    if (!a.approved && b.approved) {
                        return -1;
                    }
                    return a.masc.toLowerCase().localeCompare(b.masc.toLowerCase(), config.locale);
                });
                for (const w of sorted) {
                    yield [w.id, new Noun(config, w)];
                }
            });
        }, {
            immediate: false,
        });
        return {
            config,
            dialogue: useDialogue(),
            filter,
            nounsAsyncData,
            dictionarytable,
            form: useTemplateRef<InstanceType<typeof NounSubmitForm>>('form'),
        };
    },
    data() {
        return {
            genders,
        };
    },
    computed: {
        nouns() {
            if (this.nounsAsyncData.status.value !== 'success') {
                return {};
            }
            return this.nounsAsyncData.data.value!;
        },
        visibleNouns() {
            return Object.values(this.nouns).filter((n) => n.matches(this.filter));
        },
    },
    mounted() {
        if (this.load) {
            this.loadNouns();
        }
    },
    methods: {
        async loadNouns(): Promise<void> {
            if (this.nounsAsyncData.data.value !== null) {
                return;
            }
            await this.nounsAsyncData.execute();
        },
        async reloadNouns(): Promise<void> {
            await this.nounsAsyncData.execute();
            this.form?.focus(false);
        },
        edit(noun: Noun): void {
            this.form?.edit(noun);
        },
        async approve(noun: Noun): Promise<void> {
            await this.dialogue.postWithAlertOnError(`/api/nouns/approve/${noun.id}`);
            if (noun.base) {
                delete this.nouns[noun.base];
            }
            noun.approved = true;
            noun.base = null;
        },
        async hide(noun: Noun): Promise<void> {
            await this.dialogue.postWithAlertOnError(`/api/nouns/hide/${noun.id}`);
            noun.approved = false;
        },
        async remove(noun: Noun): Promise<void> {
            await this.dialogue.confirm(this.$t('crud.removeConfirm'), 'danger');

            await this.dialogue.postWithAlertOnError(`/api/nouns/remove/${noun.id}`);
            delete this.nouns[noun.id];
        },
    },
});
</script>

<style lang="scss">
    @import "assets/variables";

    tr {
        .hover-show {
            opacity: 0;
        }
        &:hover .hover-show {
            opacity: 1;
        }
    }

    .btn-concise {
        white-space: nowrap;
    }
    @include media-breakpoint-up('md', $grid-breakpoints) {
        .list-btn-concise {
            min-width: 3rem;

            li {
                height: 2.5rem;
            }
        }
        .btn-concise {
            position: absolute;

            .btn-label {
                display: none;
            }

            &:hover .btn-label {
                display: inline;
            }
        }
    }

    .div-three-columns {
        width: 300%;
        position: relative;
        left: -200%;
    }
</style>
